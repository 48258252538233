import './main.scss';
import Inputmask from "inputmask/dist/inputmask.min.js";
import { Fancybox } from "@fancyapps/ui";
import lozad from 'lozad/dist/lozad.min.js';
import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination]);

const alertify = require('alertifyjs/build/alertify.min.js');

document.addEventListener('DOMContentLoaded', () => {
	/**
	 * Helpers
	 */
	const cyrValue = str => {
		return /[^а-яА-ЯёЁ -]/ig.test(str);
	}
	const fancyboxShow = (src, type) => {
		Fancybox.show([
			{
				src: src,
				type: type,
			},
		])
	}




	let scrollpos = window.scrollY; 
	const header = document.querySelector(".header");
	const header_height = header.offsetHeight;

	const add_class_on_scroll = () => header.classList.add("header--scroll");
	const remove_class_on_scroll = () => header.classList.remove("header--scroll");
	
	if (scrollpos >= header_height) { add_class_on_scroll() }

	window.addEventListener('scroll', function() {
		scrollpos = window.scrollY;
		if (scrollpos >= header_height) { add_class_on_scroll() }
		else { remove_class_on_scroll() }
	})


	
	/**
	 * Field's validation
	 */
	if (document.querySelector('input[name="agreement"]')) {
		document.querySelectorAll('input[name="agreement"]').forEach(el => {
			el.addEventListener('change', e => {
				const status = e.currentTarget.checked;
				
				if (status) {
					e.currentTarget.closest('form').querySelector('.form__submit button').removeAttribute('disabled');
					e.currentTarget.closest('form').querySelector('.form__submit button').classList.remove('disabled');
				} else {
					e.target.closest('form').querySelector('.form__submit button').setAttribute('disabled', '');
					e.currentTarget.closest('form').querySelector('.form__submit button').classList.add('disabled');
				}
			})
		})
	}
	if (document.querySelectorAll('input[name="name"]')) {
		document.querySelectorAll('input[name="name"]').forEach(el => {
			let counter = 0;
			
			el.addEventListener('keyup', e => {
				if (cyrValue(e.target.value)) {
					counter++;
					
					if (counter > 5) {
						alertify.notify('Внимание! <br> Имя должно быть на русском языке', 'warning', 5);
						counter = 0;
					}
				}
				
				e.target.value = e.target.value.replace(/[^а-яА-ЯёЁ -]/ig, '');
			})
		})
	}
	if (document.querySelector('input[name="phone"]')) {
		document.querySelectorAll('input[name="phone"]').forEach(el => {
			let im = new Inputmask({
				mask: '+7 (e99) 999-99-99',
				definitions: {
					"e": {
						validator: "[0-7,9]"
					}
				}
			});
			im.mask(el);
			
			el.addEventListener('blur', e => {
				if ((e.currentTarget.value[17] == '_') || (e.currentTarget.value[17] == undefined)) {
					alertify.notify('Внимание! <br> Некорректный номер', 'warning', 5);
					e.target.parentNode.classList.add('error');
					e.target.parentNode.classList.remove('valid');
				} else {
					e.target.parentNode.classList.add('valid');
					e.target.parentNode.classList.remove('error');
				}
			})
		})
	}
	if (document.querySelector('input[required]')) {
		document.querySelectorAll('input[required]').forEach(el => {
			el.addEventListener('blur', e => {
				if (e.target.value.length == 0) {
					alertify.notify('Внимание! <br> Поле обязательно для заполнения', 'warning', 5);
				}
			})
		})
	}
	if (document.querySelector('input[type="text"], input[type="email"], textarea')) {
		document.querySelectorAll('input[type="text"], input[type="email"], textarea').forEach(el => {
			el.addEventListener('blur', e => {
				if (e.target.value.length > 0) {
					e.target.parentNode.classList.add('valid')
				} else {
					e.target.parentNode.classList.remove('valid')
				}
			})
		})
	}
	
	/**
	 * Mobile menu
	 */


	if (document.querySelector('.btn__hamburger')) {
		document.querySelector('.btn__hamburger').addEventListener('click', e => {
			e.currentTarget.classList.toggle('is-active');

			if (e.currentTarget.classList.contains('is-active')) {
				document.querySelector('.header__mobile').classList.add('is-active');
				document.querySelector('.header__mobile__mask').classList.add('is-active');
				document.querySelector('body').classList.add('overflow-hidden');
			} else {
				document.querySelector('.header__mobile').classList.remove('is-active');
				document.querySelector('.header__mobile__mask').classList.remove('is-active');
				document.querySelector('body').classList.remove('overflow-hidden');
			}
		})
		document.querySelector('.header__mobile__mask').addEventListener('click', () => {
			document.querySelector('.btn__hamburger').classList.remove('is-active');
			document.querySelector('.header__mobile').classList.remove('is-active');
			document.querySelector('.header__mobile__mask').classList.remove('is-active');
			document.querySelector('body').classList.remove('overflow-hidden');
		})
	}
	
	
	/**
	 * Lazy load
	 */
	const observer = lozad();
	observer.observe();
	
	
	/**
	 * Form
	 */

	if (document.querySelector('form')) {
		document.querySelectorAll('form').forEach(form => {
			form.addEventListener('submit', evt => {
				evt.preventDefault();
				let phone = form.querySelector('input[name="phone"]').value;
				if ((phone.length[17] == '_') || (phone.length == 0)) {
					document.querySelector('input[name="phone"]').classList.add('is-error');
				} else {
					let data = new FormData(form);
					mna100('email', data, r => {
						if (r.status == 1) {
							if (form.querySelector('input[type="submit"]')) form.querySelector('input[type="submit"]').removeAttribute('disabled', '');
							if (form.querySelector('button[type="submit"]')) form.querySelector('button[type="submit"]').removeAttribute('disabled', '');
							Fancybox.close();

							form.querySelectorAll('input').forEach(inp => {
								if ((inp.type !== 'submit') && (inp.type !== 'checkbox') && (inp.type !== 'radio') && (inp.type !== 'hidden')) {
									inp.value = '';
									inp.parentNode.classList.remove('valid');
								}
							})
							if (form.querySelector('textarea')) {
								form.querySelector('textarea').value = '';
								form.querySelector('textarea').parentNode.classList.remove('valid');
							}
							if (form.querySelector('input[type="checkbox"]')) {
								form.querySelectorAll('input[type="checkbox"]').forEach(el => {
									if (el.name != 'agreement') el.checked = false;
								})
							}

							setTimeout(() => {
								fancyboxShow('#thanks', 'inline');
							}, 150);
							setTimeout(() => {
								Fancybox.close();
							}, 5000);

						} else {
							alertify.notify('Ошибка! ' + r.message, 'warning', 5);
							Fancybox.close();
						}
					})
				}
			})
		})
	}


	
	/**
	 * Form select
	 */

	if(document.querySelector(".select-toggle")) {
		document.querySelectorAll(".select-toggle").forEach(item => {
			item.addEventListener('click', () => {
				item.parentNode.classList.toggle('is-active');
			})
		})
	}

	if (document.querySelector('.option__item')) {
		document.querySelectorAll('.option__item').forEach(select => {
			select.addEventListener('click', () => {
				let value = select.innerText,
					parentBlock = select.closest('.select'),
					select_curr = parentBlock.querySelector('.select-value'),
					input = parentBlock.querySelector('.select-input');

					parentBlock.classList.remove('is-active');
					parentBlock.classList.add('is-filled');
					select_curr.innerText = value;
					input.value = value;
			});
		})
	}

	

	
	
	/**
	 * JS redirect
	 */
	if (document.querySelector('*[data-link]')) {
		document.querySelectorAll('*[data-link]').forEach(el => {
			el.addEventListener('click', e => {
				e.preventDefault();
				
				let url = e.currentTarget.dataset.link;
				
				document.location.href = url;
			})
		})
	}
	
	
	/**
	 * Modal
	 */
	if (document.querySelector('.modal-link')) {
		document.querySelectorAll('.modal-link').forEach(el => {
			el.addEventListener('click', evt => {
				evt.preventDefault();
				
				const button  = evt.currentTarget,
							target  = button.hash || button.dataset.href,
							popup   = document.querySelector(target),
							title   = button.dataset.modalTitle,
							content = button.dataset.modalContent,
							footer  = button.dataset.modalFooter,
							submit  = button.dataset.modalSubmit,
							yagoal  = button.dataset.modalYagoal,
							email   = button.dataset.modalEmail;
				
				if (title) popup.querySelector('.modal__title span').innerHTML    = title;
				if (submit) popup.querySelector('input[type="submit"]').value     = submit;
				if (yagoal) popup.querySelector('input[name="ya_goal"]').value    = yagoal;
				if (email) popup.querySelector('input[name="email_title"]').value = email;
				if (target) fancyboxShow(target, 'inline');
			})
		})
	}


	/**
	 * Scroll to
	 */

	function scrollTo(element) {
		window.scroll({
			behavior: 'smooth',
			left: 0,
			top: element.offsetTop - 80
		});
	}

	if(document.querySelector(".btn_scroll_to-form")) {
		document.querySelectorAll(".btn_scroll_to-form").forEach(btn => {
			btn.addEventListener('click', e => {
				e.preventDefault();
				scrollTo(document.getElementById("form_vacancy"));
			})
		})
	}

	if(document.querySelector(".btn_scroll_to-vacancy")) {
		document.querySelectorAll(".btn_scroll_to-vacancy").forEach(btn => {
			btn.addEventListener('click', e => {
				e.preventDefault();
				scrollTo(document.getElementById("vacancy_list"));
			})
		})
	}

	if(document.querySelector(".button-vacancy")) {
		document.querySelectorAll(".button-vacancy").forEach(btn => {
			btn.addEventListener('click', e => {
				e.preventDefault();
				scrollTo(document.getElementById("form_vacancy"));
				let title_select = btn.dataset.vacancyTitle,
					select_curr = document.querySelector('.select .select-value'),
					input = document.querySelector('.select .select-input');

				select_curr.innerText = title_select;
				input.value = title_select;
			})
		})
	}



	/**
	 * Accordeon toggle
	 */
	if (document.querySelector('.accordeon')) {
		document.querySelectorAll('.accordeon__item__headered').forEach(btn => {
			btn.addEventListener('click', e => {
				btn.parentElement.classList.toggle('is-active');
			})
		})
	}

	
	/**
	 * Sliders
	 */

	if (document.querySelector('.swiper-container.video-slider')) {
		let commonServicesSwiper = new Swiper('.swiper-container.video-slider', {
			breakpoints: {
				320 : {
					slidesPerView: 1
				},
				520 : {
					slidesPerView: 2,
				},
				992 : {
					slidesPerView: 3
				},
			},
			loop: true,
			lazy: true,
			navigation: {
				nextEl: '.swiper-button-next.video-slider-button',
				prevEl: '.swiper-button-prev.video-slider-button',
			},
			pagination: {
				el: '.swiper-pagination.video-slider-pagination',
				type: 'bullets',
				clickable: true,
			},
			observer: true,
			observeParents: true,
			preloadImages: true,
			spaceBetween: 20
		});
	};

	if (document.querySelector('.swiper-container.products-slider')) {
		let commonServicesSwiper = new Swiper('.swiper-container.products-slider', {
			breakpoints: {
				320 : {
					slidesPerView: 1
				},
				520 : {
					slidesPerView: 2
				},
				768 : {
					slidesPerView: 3,

				},
				992 : {
					slidesPerView: 4
				},
			},
			loop: true,
			lazy: true,
			navigation: {
				nextEl: '.swiper-button-next.products-slider-button',
				prevEl: '.swiper-button-prev.products-slider-button',
			},
			pagination: {
				el: '.swiper-pagination.products-slider-pagination',
				type: 'bullets',
				clickable: true,
			},
			observer: true,
			observeParents: true,
			preloadImages: true,
			spaceBetween: 70
		});
	};


	/*
		Animates waypoints
	 */

	window.addEventListener("load", function() {

		let advantages_items = document.querySelectorAll('.banner__content__advantages__item');
		let c = 0;
		let timer = setInterval(function() {
			advantages_items[c].classList.add('animate');
			c++;
			if (c === advantages_items.length) {
				clearInterval(timer);
			}
		}, 500);

		document.querySelector('.banner__content__image').classList.add('animate');
	})



	let waypoint1 = new Waypoint({
		element: document.getElementById('work_section'),
		handler: function(direction) {
			if (direction == 'down') {
				let work_items = document.querySelectorAll('.main__work__item');
				let c = 0;
				let timer_work = setInterval(function() {
					work_items[c].classList.add('animate');
					c++;
					if (c === work_items.length) {
						clearInterval(timer_work);
					}
				}, 500);
			}
		},
		offset: '60%'
	});


	document.querySelectorAll('.main__vacancy__item').forEach(el => {

		let waypoint = new Waypoint({
			element: el,
			handler: function(direction) {
				if (direction == 'down') {
					el.classList.add("animate");
				}
			},
			offset: '60%'
		});

	})


	let waypoint2 = new Waypoint({
		element: document.getElementById('conditions_section'),
		handler: function(direction) {
			if (direction == 'down') {
				let condition_items = document.querySelectorAll('.main__conditions__item');
				let c = 0;
				let timer = setInterval(function() {
					condition_items[c].classList.add('animate');
					c++;
					if (c === condition_items.length) {
						clearInterval(timer);
					}
				}, 500);
			}
		},
		offset: '60%'
	});

	let waypoint3 = new Waypoint({
		element: document.getElementById('about_section'),
		handler: function(direction) {
			if (direction == 'down') {
				document.querySelector('.main__about__image').classList.add('animate');;
			}
		},
		offset: '60%'
	});



	/*
		load more
	 */

	if (document.querySelector('.load_more')) {
		document.querySelector('.load_more').addEventListener('click', e => {
			e.preventDefault();
			let current_count = document.querySelector('.load_more').dataset.activeVacancy,
				numb_vacancy = document.querySelectorAll('.main__vacancy__item').length;

			document.querySelectorAll(".main__vacancy__item").forEach(function (item, i) {

				if ((i > (current_count - 1)) && (i < (+current_count + 7))) {
					item.classList.add("is-active");
				}
			});


			current_count = +current_count + 7;
			document.querySelector('.load_more').setAttribute("data-active-vacancy", current_count);

			if (current_count >= numb_vacancy) {
				document.querySelector('.load_more').classList.add("is-hidden");
			}

		})
	}






})